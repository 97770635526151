<template>
  <div class="container pd100">
    <van-search v-model="keyword" class="search" placeholder="请输入搜索商品" />
    <div class="category">
      <van-sidebar v-model="categoryActive" class="category-nav">
        <van-sidebar-item v-for="item in categoryList" :key="item.cat_id" @click="onCategory" >
          <template slot="title">
            <div class="sidebar-solt-title">{{item.cat_name}}</div>
          </template>
        </van-sidebar-item>
      </van-sidebar>
      <div ref="wrapper" class="category-container">
        <div class="category-list">
          <van-row gutter="10">
            <van-col v-for="item in list" :key="item.product_id" :span="12">
              <div class="category-list-item" @click="onProductClick(item.product_id)">
                <div class="category-list-item-media">
                  <van-image class="category-list-item-media-img" :src="item.product_image" />
                </div>
                <div class="category-list-item-bd">
                  <div class="category-list-item-name"> {{ item.product_name }} </div>
                </div>
                <div class="category-list-item-ft">
                  <div class="category-list-item-price"><span class="fz12">¥</span> {{ item.sale_price }}</div>
                  <div class="category-list-item-original-price"><span class="fz12">¥</span> <span class="ui-text-deleted">{{ item.market_price }}</span> </div>
                  <!-- <div class="category-list-item-buy" @click.stop="onBuy"><van-icon name="cart-circle-o" /></div> -->
                </div>
              </div>
            </van-col>
          </van-row>
        </div>
        <van-loading v-if="listLoading" class="ui-center" size="24px" style="margin-top:24px;">加载中...</van-loading>
        <div v-if="listFinished" class="finished-text">没有更多了</div>
      </div>
    </div>
    <!-- 右侧购物车按钮 -->
    <cart />
    <!-- 底部导航栏 -->
  </div>
</template>
<script>

import { Tabbar, TabbarItem, Sidebar, SidebarItem, Search, Sticky } from 'vant'
import Cart from '@/components/Cart'
import { debounce } from '@/common/util'
// import BScroll from 'better-scroll'

export default {
  name: 'Mall',
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
    [Search.name]: Search,
    [Sticky.name]: Sticky,
    Cart
  },
  data() {
    return {
      scroll: null,
      pageTitle: '商城',
      keyword: '',
      categoryList: [],
      categoryActive: 0,
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1,
      activeBar:3

    }
  },
  watch: {
    keyword(val) {
      this.search()
    }
  },
  computed: {
  },
  created() {
    this.$api.store_cat().then(res => {
      this.categoryList = res.data
      this.queryProductPage()
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.wrapper.addEventListener('scroll', this.scrollMe)
    })
  },
  beforeDestroy() {
    this.$refs.wrapper.removeEventListener('scroll', this.scrollMe)
  },
  methods: {
    scrollMe() {
      const el = this.$refs.wrapper
      const scrollTop = el.scrollTop
      const scrollHeight = el.scrollHeight
      const clientHeight = el.clientHeight
      const offset = 50 // 阀值
      // 当内容滚动到距离底部< offset 时,且没有加载完成&&没有正在加载中
      // 内容距离底部多少距离 = 内容总高度-滚动高度-当前可视高度
      // console.log(clientHeight, scrollHeight, scrollTop)
      if (scrollHeight - scrollTop - clientHeight < offset && !this.listLoading && !this.listFinished) {
        console.log('加载更多')
        this.pageCurrent += 1
        this.queryProductPage()
      }
    },
    search: debounce(function() {
      const el = document.querySelector('.category-container')
      if (el) {
        el.scrollTo(0, 0)
      }

      this.pageCurrent = 1
      this.listLoading = true
      this.listFinished = false
      this.queryProductPage()
    }, 300),
    onCategory(index) {
      const el = document.querySelector('.category-container')
      if (el) {
        el.scrollTo(0, 0)
      }

      this.pageCurrent = 1
      this.listLoading = true
      this.listFinished = false
      this.categoryActive = index
      this.queryProductPage()
    },
    queryProductPage() {
      this.listLoading = true
      const params = {
        'per-page': this.pageSize,
        page: this.pageCurrent
      }
      this.keyword.trim() && (params.q = this.keyword)
      params.cat_id = this.categoryList[this.categoryActive].cat_id

      this.$api.store_index(params).then(res => {
        this.listLoading = false
        this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
        this.listFinished = this.list.length >= res._meta.totalCount
        this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
      })
    },
    onProductClick(id) {
      this.$router.push({ path: '/weistore-product', query: { product_id: id }})
    },
    handleRouterChange(e){
      if(e === 0){
        this.$router.push({path:'/home'})
      }else if(e === 1){
        this.$router.push({path:'/recruit',query:{appid:localStorage.getItem('appid')}})
      }else if(e === 2){
        this.$router.push({path:'/schedule'})
      }else if(e === 4){
        let num = localStorage.getItem('profile_employee')
        if(num == 1){
          this.$router.push({path:'/profile'})
        }else{
          this.$router.push({path:'/user'})
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
  
  .search{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .category{
    position: fixed;
    top: 60px;
    left: 0;
    padding-left: 90px;
    background: #fff;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    &-nav{
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background: #fafafa;
    }
  }
  .category-container{
    box-sizing: border-box;
    padding: 0 10px;
    flex: 1;
    width: 285px;
    background: #fff;
    height: 553px;
    overflow-y: scroll;
  }
  .category-list-item{
    background: #fff;
    margin-bottom: 10px;
    &-media{
      &-img{
        width: 120px;
        height: 120px;
      }
    }
    &-bd{
      // padding: 0 10px;
    }
    &-ft{
      // padding: 0 10px;
      display: flex;
      align-items: center;
      color: @ui-color--red;
      font-size: 14px;
      justify-content: space-between;
    }
    &-name{
      width: 100%;
      color: #333;
      font-size: 14px;
      height: 40PX;
      max-height: 40PX;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    &-price{
      color: @ui-color--red;
      font-size: 12px;
    }
    &-original-price{
      color: #999;
      font-size: 14px;
    }
    &-buy{
      font-size: 20px;
    }

  }

  .finished-text{
    color: #969799;
    font-size: 14px;
    line-height: 50px;
    text-align: center;
  }
  .sidebar-solt-title{
    width: 56px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: break-all;
  }
</style>


<template>
  <div class="cart-bar-wrapper">
    <div class="cart-bar">
      <div class="cart-bar-backtop anim-scale" @click="goCart"><van-icon :size="22" :badge="count" color="#7d7e80" name="shopping-cart-o" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CartWindowBtn',
  props: {
    // type: {
    //   type: String,
    //   default: 'campus'
    // },
    // config: {
    //   type: Object,
    //   default: () => {}
    // }
  },
  data() {
    return {
      backTop: true,
      count: 0

    }
  },
  created() {
    console.log(1)
    this.$api.cart_count().then(res => {
      this.count = res.data.count ? Number(res.data.count) : null
    })
  },
  methods: {
    goCart() {
      this.$router.push({ path: '/cart' })
    }
  }
}
</script>
<style lang="less" scoped>

  .cart-bar{
  position:fixed;
  right: 15px;
  bottom:100px;
  z-index:99;
  min-height:80px;
  &-share,&-backtop{
    box-sizing: border-box;
    background: #fff;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #7d7e80;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 2px 3px hsla(0,0%,86.3%,.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.anim-scale {
    animation-name: anim-scale;
    animation-duration: .3s;
    animation-fill-mode: both;
}
@keyframes anim-scale {
    0% {
        opacity: .3;
        -ms-transform: scale(.5);
        transform: scale(.5)
    }

    100% {
        opacity: 1;
        -ms-transform: scale(1);
        transform: scale(1)
    }
}
</style>

